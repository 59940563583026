import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const CustomerStockService = () => {}

CustomerStockService.GetCustomerStock = async(branchId)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranchStock/branch/${branchId}`, {})
}

CustomerStockService.CheckDupSerial = async(queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranchStock/checkSerial?${queryString}`, {})
}

CustomerStockService.GetCustomerStockById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/customerBranchStock/${id}`, {})
}

CustomerStockService.InsertCustomerStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/customerBranchStock`, payload)
}

CustomerStockService.UpdateCustomerStock = async(payload, id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/customerBranchStock/${id}`, payload)
}

CustomerStockService.DeleteCustomerStock = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/customerBranchStock/${id}`, {})
}



 export default CustomerStockService