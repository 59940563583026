<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="8">
        <h3><b>จัดการลูกค้า</b></h3>
        <small>
              <span class="text-primary" style="cursor: pointer;" @click="$router.push('/setting/customer/owner')"><b>จัดการลูกค้า</b> ></span>
              <span class="text-primary"  @click="$router.push(navPath('company'))" style="cursor: pointer;"><b> {{$route.query.ownerName}}</b> ></span>
              <span class="text-primary"  @click="$router.push(navPath('subCompany'))" style="cursor: pointer;"><b> {{$route.query.companyName}}</b> ></span> 
              <span class="text-primary"  @click="$router.push(navPath('branch'))" style="cursor: pointer;"><b> {{$route.query.subCompanyName}}</b> ></span>                         
              <span class="text-primary"  @click="$router.push(navPath('previous'))" style="cursor: pointer;"><b> {{$route.query.branchName}}</b> > </span>   
          <b>Stock</b>

        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="customerPermission=='create' || customerPermission=='edit'"          
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
          :disabled="isLoading"
        >
          <feather-icon icon="SaveIcon" />
          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push(navPath('previous'))"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-overlay
      :show="isLoading"
      rounded="lg"
      opacity="0.55"
      blur="2px"
      variant="transparent"
      fixed
    >
      <b-row class="mt-1">
        <b-col cols="12">
          <!-- START SECTION FORM วันเดินทาง--->
          <b-card>
            <b-row>
              <b-col
                >
                <h2>
                  <b v-if="$route.query.stid">แก้ไข Stock</b>
                  <b v-else>เพิ่ม Stock</b>
                </h2>
                </b-col>
            </b-row>
            <!--- START (Serial) --->
            <b-row class="mt-2">
              <b-col md="6" class="">
                <label>Serial No. <span class="text-danger">*</span></label>
                <b-form-input
                  placeholder="1851212151212"
                  size="sm"
                  value="Vserv"
                  v-model="stockData.serial"
                />
              </b-col>

              <b-col md="2" class="">
                <label>สถานะ</label>
                <b-form-group>
                  <b-form-radio-group
                    :options="statusOptions"
                    name="radio-inline"
                    v-model="stockData.status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!--- END (Serial) --->

            <!--- START (Hardware) --->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label>Hardware type</label>
                <v-select
                  id="brand"
                  v-model="hwTypeSelected"
                  label="hwTypeName"
                  :options="hwTypeList"
                  :clearable="false"
                  class="select-size-sm"
                  @input="onChangeHwType"
                />
              </b-col>

              <b-col md="3" class="">
                <label>Brand</label>
                <v-select
                  id="brand"
                  v-model="hwBrandSelected"
                  label="hwBrandName"
                  :options="hwBrandList"
                  :clearable="false"
                  class="select-size-sm"
                  @input="onChangeHwBrand"
                />
              </b-col>

              <b-col md="3" class="">
                <label>Model</label>
                <v-select
                  id="brand"
                  v-model="hwModelSelected"
                  label="hwModelName"
                  :options="hwModelList"
                  :clearable="false"
                  class="select-size-sm"
                />
              </b-col>
            </b-row>
            <!--- END (Hardware) --->

            <!--- START (เบอร์โทร) --->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label>IP Address</label>
                <b-form-input
                  placeholder="1.0002292"
                  size="sm"
                  value="1.0002292"
                  v-model="stockData.ipAddress"
                />
              </b-col>

              <b-col md="3" class="">
                <label>Teamviewer ID</label>
                <b-form-input
                  placeholder="1.223444"
                  size="sm"
                  value="1.223444"
                  v-model="stockData.teamviewer"
                />
              </b-col>

              <b-col md="3" class="">
                <label>วันที่เริ่มต้นรับประกัน</label>
                <b-form-datepicker
                  id="startdate"
                  locale="th-TH"
                  show-decade-nav
                  class="mb-1"
                  size="sm"
                  v-model="stockData.warrantyStartDate"
                />
              </b-col>
              <b-col md="3" class="">
                <label>วันที่เริ่มสิ้นสุดรับประกัน</label>
                <b-form-datepicker
                  id="enddate"
                  locale="th-TH"
                  show-decade-nav
                  class="mb-1"
                  size="sm"
                  v-model="stockData.warrantyEndDate"
                />
              </b-col>
            </b-row>
            <!--- END (เบอร์โทร) --->

            <!--- START ROW รายละเอียด --->
            <b-row class="mt-1">
              <b-col md="12">
                <label>รายละเอียด</label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="รายละเอียด"
                  rows="2"
                  size="sm"
                  v-model="stockData.description"
                />
              </b-col>
            </b-row>
            <!--- END รายละเอียด --->
          </b-card>
          <!-- END SECTION FORM วันเดินทาง--->
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
  BFormDatepicker,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import HardwareService from "@/services/setting/hardware";
import CustomerStockService from "@/services/setting/customerStock";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormDatepicker,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      initSerial:"",
      statusOptions: [
        { text: "เปิด", value: 1 },
        { text: "ปิด", value: 0 },
      ],
      stockData: { status: 1 },
      hwTypeList: [],
      hwTypeSelected: {},
      hwBrandList: [],
      hwBrandSelected: {},
      hwModelList: [],
      hwModelSelected: {},
      isLoading: false,
      mode:"create"
    };
  },

  async created() {  
    const qBranchStockId = this.$route.query.stid;
    if (qBranchStockId) {
      await this.getBranchStockById(qBranchStockId);
    }
    await this.getHwType();
  },

  computed: {
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },


  watch:{
    stockData(data){
      if(data.id){
        this.mode = "edit"  
      }else{
        this.mode = "create"
      }
    },
  },

  methods: {
      navPath(type){
      switch (type) {
        case "previous":
          return `/setting/customer/branch/stock?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&scid=${this.$route.query.scid}&bid=${this.$route.query.bid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}&subCompanyName=${this.$route.query.subCompanyName}&branchName=${this.$route.query.branchName}`
        case "subCompany":
          return `/setting/customer/sub-company?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}&subCompanyName=${this.$route.query.subCompanyName}`
        case "company":
          return `/setting/customer/company?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}`
        case "branch":
          return `/setting/customer/branch?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&scid=${this.$route.query.scid}&bid=${this.$route.query.bid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}&subCompanyName=${this.$route.query.subCompanyName}&branchName=${this.$route.query.branchName}`
        
      
        default:
          break;
      }
    },
    async save() {
      if(!this.stockData.serial){
        alert("กรุณากรอก Serial")
        return
      }

      if (this.stockData.id) {
        await this.update();
      } else {
        await this.insert();
      }
    },

    async insert() {
      const checked = await this.checkSerial()
      if(checked){
        alert("Serial ซ้ำ! กรุณากรอก serial ใหม่")
        return
      }

      this.isLoading = true;
      const obj = {
        ...this.stockData,
        branchId: this.$route.query.bid,
        hwTypeId: this.hwTypeSelected.id,
        brandId: this.hwBrandSelected.id,
        modelId: this.hwModelSelected.id,
      };
      const result = await CustomerStockService.InsertCustomerStock(obj);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Branch Stock`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });

        this.$router.push(this.navPath('previous'))
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Branch Stock`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },
    async update() {

      const checked = await this.checkSerial()
      if(checked){
        alert("Serial ซ้ำ! กรุณากรอก serial ใหม่")
        return
      }
      
      this.isLoading = true;
      const obj = {
        ...this.stockData,
        branchId: this.$route.query.bid,
        hwTypeId: this.hwTypeSelected.id,
        brandId: this.hwBrandSelected.id,
        modelId: this.hwModelSelected.id,
      };
      const result = await CustomerStockService.UpdateCustomerStock(
        obj,
        obj.id
      );
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Branch Stock`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });

        
        this.$router.push(this.navPath('previous'))
        
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Branch Stock`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }

      this.isLoading = false;
    },

    async getBranchStockById(qBranchStockId) {
      const result = await CustomerStockService.GetCustomerStockById(
        qBranchStockId
      );
      this.stockData = result.data;
      this.initSerial = result.data.serial

      const findedType = this.hwTypeList.find(
        (f) => f.id == result.data.hwTypeId
      );
      const findedBrand = this.hwBrandList.find(
        (f) => f.id == result.data.brandId
      );
      const findedModel = this.hwModelList.find(
        (f) => f.id == result.data.modelId
      );

      this.hwTypeSelected = findedType;
      this.hwBrandSelected = findedBrand;
      this.hwModelSelected = findedModel;
    },

    async checkSerial(){
      const serial = this.stockData.serial
      const branchId =  this.$route.query.bid      
      const modelId = this.hwModelSelected.id

      //IF SAME VALUE
      if(this.initSerial == this.stockData.serial){
        return false
      }

      const result = await CustomerStockService.CheckDupSerial(`serial=${serial}&modelId=${modelId}&branchId=${branchId}`);
      return result.data.data.duplicate || false
    },

    async getHwType() {
      const result = await HardwareService.GetHwTypeList();
      this.hwTypeList = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwTypeList.find(
            (f) => f.id == this.stockData.hwTypeId
          );
          if(!item){
            item = result.data[0];
          }    
        }     
       
        this.hwTypeSelected = item;
        await this.onChangeHwType(item);
      }else{
        this.hwTypeSelected = {}
      }
    },

    async getHwBrand(typeId) {  
      const result = await HardwareService.GetHwBrandList(typeId);
      this.hwBrandList = result.data;
      

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwBrandList.find(
            (f) => f.id == this.stockData.brandId
          );

          if(!item){
            item = result.data[0];
          }       
        }
       
        this.hwBrandSelected = item;
        await this.onChangeHwBrand(item);
      }else{
        this.hwBrandSelected = {}
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId);
      this.hwModelList = result.data;

      if (result.data.length > 0) {
        if (this.mode == "create") {
          this.hwModelSelected = result.data[0];
        } else {
          let findHwModel = this.hwModelList.find(
            (f) => f.id == this.stockData.hwModelId
          );
          
          if(!findHwModel){
            findHwModel = result.data[0];
          }  
          this.hwModelSelected = findHwModel;
        }
      }else{
        this.hwModelSelected = {}
      }
    },

    onChangeHwType(item) {
      this.getHwBrand(item.id);
    },

    onChangeHwBrand(item) {
      this.getHwModel(item.id);
    },
   
  },

  async mounted() {},
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
